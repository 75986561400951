import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap"

import Socials from "./socials"

const Header = () => {
  return (
    <>
      <div className="preheader bg-light-blue">
        <Container>
          <Row>
            <Col className="phone" md={6} xs={7}>
              <span>
                Ligue:{" "}
                <a
                  href="https://wa.me/+5553981551905"
                  target="_blank"
                  rel="noreferrer"
                  className="mr-1 mr-md-2"
                >
                  (53) 9.81551905
                </a>
              </span>
            </Col>
            <Col className="text-right socials">
              <Socials />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="header bg-blue">
        <Container>
          <Navbar variant="dark" expand="lg" className="px-0">
            <Link
              to="/"
              className="navbar-brand p-0 py-1"
              activeClassName="active"
            >
              <StaticImage
                src="../../images/common/logo.png"
                height={43}
                alt="Logo"
                placeholder="tracedSVG"
              />
            </Link>
            <Navbar.Toggle aria-controls="main-navbar" />
            <Navbar.Collapse id="main-navbar">
              <Nav className="ml-auto align-self-stretch align-items-center">
                <Link
                  to="/"
                  className="nav-link menu-item"
                  activeClassName="active"
                >
                  Home
                </Link>
                <Link
                  to="/institucional"
                  className="nav-link menu-item"
                  activeClassName="active"
                >
                  Institucional
                </Link>
                <Link
                  to="/cursos"
                  className="nav-link menu-item"
                  activeClassName="active"
                >
                  Cursos
                </Link>
                <Link
                  to="/blog"
                  className="nav-link menu-item"
                  activeClassName="active"
                >
                  Blog
                </Link>
                <Link
                  to="/contato?ref=menu"
                  className="nav-link menu-item"
                  activeClassName="active"
                >
                  Contato
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    </>
  )
}

export default Header
