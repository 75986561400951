import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Seo = ({ description, lang, meta, title }) => {
  const metaDescription = description || "";
  const author = `Me Gusta Digital`;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={"Ctemer"}
      titleTemplate={title ? `${title} | %s` : `%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title || ``,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script src="/gtm.js" type="text/javascript" />
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `pt-br`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default Seo;
