import * as React from "react"

import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai"

const SocialsComponent = () => {
  return (
    <>
      <div className="social-links">
        <a
          href="https://www.facebook.com/ctemercursos/"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineFacebook />
        </a>
        <a
          href="https://www.instagram.com/ctemer_cursos"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineInstagram />
        </a>
      </div>
    </>
  )
}

export default SocialsComponent
