import * as React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Nav } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Socials from "./socials"
import { Parallax } from "react-parallax"

import bgImage from "../../images/home/parallax2.png"
import { useLocation } from "@reach/router"

const Footer = () => {
  const pathname = useLocation().pathname

  return (
    <footer>
      <div style={{ width: "100%", height: 350 }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13569.07973051496!2d-52.345435!3d-31.7631229!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x345cccb894a454a3!2sCTEMER%20-%20Emergency%20Training%20Center!5e0!3m2!1spt-BR!2sbr!4v1649363779405!5m2!1spt-BR!2sbr"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          title="Google Map"
        ></iframe>
      </div>
      {pathname === "/" && (
        <Parallax
          blur={5}
          bgImage={bgImage}
          bgImageAlt="the dog"
          strength={200}
        >
          <Container className="padding-top padding-bottom">
            <Row className="align-items-center">
              <Col md={3}>
                <div className="title">
                  <h1>
                    Seja nosso <span>parceiro</span>
                  </h1>
                  <div className="separator mb-md-0"></div>
                </div>
              </Col>
              <Col>
                Leve o CTEMER para a sua região e confira as vantagens em ser
                nosso parceiro(a) na sua cidade.
              </Col>
              <Col md={2} className="text-center text-md-right mt-5 mt-md-0">
                <Link className="btn btn-primary" to="/contato?ref=parceiro">
                  Saiba mais
                </Link>
              </Col>
            </Row>
          </Container>
        </Parallax>
      )}
      <div className="bg-blue padding-top medium-padding-bottom">
        <Container>
          <Row className="align-items-center">
            <Col md={3}>
              <Nav
                defaultActiveKey="/"
                className="flex-column footer-menu d-none d-md-flex"
              >
                <Link to="/" className="nav-link">
                  Home
                </Link>
                <Link to="/institucional" className="nav-link">
                  Institucional
                </Link>
                <Link to="/cursos" className="nav-link">
                  Cursos
                </Link>
                <Link to="/blog" className="nav-link">
                  Blog
                </Link>
                <Link to="/contato?ref=menu" className="nav-link">
                  Contato
                </Link>
              </Nav>
            </Col>
            <Col className="address">
              <div>
                <h4>Escola de Saúde CTEMER</h4>
                <p>Santos Dumont, 329 - Bairro Centro - Pelotas</p>
              </div>
              <div>
                <h4>Centro de Simulação em Atendimento Pré Hospitalar</h4>
                <p>Rua 02 número 1725 - Bairro Germani - Pelotas</p>
              </div>
              <div>
                <p className="mb-0">Telefone: (53) 3228.4804</p>
              </div>
              <div>
                <p className="mb-0">Telefone/Whatsapp: (53) 98155.1905</p>
              </div>
            </Col>
            <Col md={3} className="text-center text-md-right pt-4 pt-md-0">
              <StaticImage
                src="../../images/common/logo.png"
                alt="Logo"
                layout="constrained"
                placeholder="tracedSVG"
                width={220}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="postfooter bg-light-blue">
        <Container>
          <Row>
            <Col>© CTemer 2021</Col>
            <Col className="socials text-right">
              <Socials />
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}

export default Footer
